import React from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {X} from "react-bootstrap-icons"
import Image from "next/image"
import FormNewsletter from "../../forms/FormNewsletter"
import {Color} from "../FormInput/FormInput"
import styles from "./DiscountForNewsletterModalContent.module.scss"

type Props = {closeModal: ()=>void}

const DiscountForNewsletterModalContent: React.FC<Props> = ({closeModal}) => {
	const {t} = useTranslation()
	return (

		<div className={`${styles.fixedWrapper}`}>
			<div className={`${styles.modalWrapper} position-relative px-md-0 `}>

				<button onClick={() => closeModal()}
					className={"d-none d-md-block position-absolute btn btn-light btn-outline-light rounded-circle p-0 top-0 end-0"} style={{
						marginTop: "2%",
						marginRight: "3%",
						zIndex: 1
					}}>
					<X size={30} color={"black"}/>
				</button>

				<div style={{minHeight: 208, borderTopRightRadius: "16px", borderTopLeftRadius: "16px"}} className="d-md-none col-12 p-0 ps-2 overflow-hidden position-relative">
					<Image src="/assets/images/header-slider-img-two.jpg" objectFit="cover" layout="fill"/>
				</div>

				<div className="row position-relative mt-md-3 mx-0 justify-content-between">
					<div className={`${styles.contentWrapper} position-relative col-12 col-md-7 bg-white py-md-4 pb-4 ps-md-4 pe-md-5 d-flex justify-content-center align-items-center`}>
						<div className="container px-0 ps-md-0">
							<button onClick={() => closeModal()}
								className={"d-md-none position-absolute bg-light btn btn-light btn-outline-light rounded-circle p-0 top-0 end-0"} style={{
									marginTop: "2%",
									marginRight: "3%",
									zIndex: 1
								}}>
								<X size={30} color={"black"}/>
							</button>
							<div className="row mb-2 mb-md-4 pt-4">
								<Typography className="col-12 mb-3 mt-3 d-md-none" variant="heading1Black" semanticTag="h2">{t("discount-newsletter-modal-title")}</Typography>
								<Typography style={{fontSize: 36}} className="col-12 mb-2 mt-4 d-none d-md-block" variant="heading1Black" semanticTag="h2">{t("discount-newsletter-modal-title")}</Typography>
								<Typography className="mb-md-3 mb-4" variant="bodyLg">{t("discount-newsletter-modal-desc")}</Typography>
							</div>
							<div className={"row mb-0"}>
								<FormNewsletter fieldBgColor={Color.white} blogNewsletter discountForNewsletter/>
							</div>

						</div>
					</div>

					<div style={{minHeight: 600}} className={`${styles.pictureWrapper} d-none d-md-block col-5 p-0 m-0 position-relative overflow-hidden`}>
						<Image src="/assets/images/header-slider-img-two.jpg" objectFit="cover" layout="fill"/>
					</div>
				</div>
			</div>
		</div>)
}

export default DiscountForNewsletterModalContent
