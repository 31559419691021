import React, {useContext, useMemo} from "react"
import SearchContext from "../../context/SearchContext"
import {useTranslation} from "next-i18next"
import {getMenuTitles} from "../../utility/Helper"
import DataContext from "../../context/DataContext"
import AnalyticsContext from "../../context/AnalyticsContext"

const PlaceholderResults = () => {
	const {value} = useContext(SearchContext)
	const {t} = useTranslation(["common", "search"])

	const {collections, brands} = useContext(DataContext)
	const menuTitles = useMemo(() => getMenuTitles(collections, brands), [collections, brands])
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div className={value?.length >= 3 ? "mt-5 mt-lg-0" : null}>
			<div className={"mb-5"}>
				{/* <Typography variant={"bodyLgBold"} semanticTag={"div"} className={"mb-3 text-secondary"}>
					{t("popular-brands-title", {ns: "search"})}
				</Typography>
				<div className={"row d-none d-md-flex"}>
					{brands.map(brand => <div key={brand.fields.filterSlug} className={"col-6 col-lg-auto mb-2"}>
						<Link href={filterUrl({}, "brand", brand.fields.filterSlug, t)}>
							<a onClick={() => {
								reactGA?.event({
									category: gaCategories.search,
									action: gaEvents.brandsBellowSearch,
									label: brand.fields.slug,
									nonInteraction: false
								})
							}}>
								<img src={getImageUrl(brand.fields.icon)} width={"auto"} height={24}/>
							</a>
						</Link>
					</div>)}
				</div>

				<div className="d-md-none row">
					{brands.map(brand => {
						const {slug, menuLabel, icon} = brand.fields
						if (icon) {
							const targetHeight = 35
							const {width, height} = icon.fields.file.details.image
							const targetWidth = targetHeight ? targetHeight * (width / height) : 140
							const aspectRatio = width / height
							const aspectHeight = targetWidth / aspectRatio
							return <BrandLogo col="col-6" logoBrandName={menuLabel} key={slug} src={getImageUrl(icon)} width={targetWidth} height={targetHeight ? targetHeight : aspectHeight} href={`/${t("brands")}/${slug}`} />
						}

						return null
					})}
				</div> */}

			</div>
			<div className={"mb-5"}>
				{/* <Typography variant={"bodyLgBold"} semanticTag={"div"} className={"mb-3 text-secondary"}>
					{t("e-bike-categories-title", {ns: "search"})}
				</Typography>
				<div className={"row"}>
					{menuTitles.collections.map(category => <div key={category.name} className={"col-6 col-lg-auto mb-2"}>

						{(category.name === "Zubehör" || category.name === "Accessoires") ? <Link href={`${category.href}`} as={`/${category.href}`}>
							<a onClick={() => {
								reactGA?.event({
									category: gaCategories.search,
									action: gaEvents.categoryBellowSearch,
									label: category.name,
									nonInteraction: false
								})
							}} className={"row link-reset g-1 align-items-center"}>
								<div className={"col-auto"}>
									<img src={category.src} width={"auto"} height={24}/>
								</div>
								<div className={"col-auto"}>
									{t(category.name, {ns: "common"})}
								</div>
							</a>
						</Link> : <Link href={`/collection${category.href}`} as={`/${t("categories", {ns: "common"})}${category.href}`}>
							<a onClick={() => {
								reactGA?.event({
									category: gaCategories.search,
									action: gaEvents.categoryBellowSearch,
									label: category.name,
									nonInteraction: false
								})
							}} className={"row link-reset g-1 align-items-center"}>
								<div className={"col-auto"}>
									<img src={category.src} width={"auto"} height={24}/>
								</div>
								<div className={"col-auto"}>
									{t(category.name, {ns: "common"})}
								</div>
							</a>
						</Link>
						}

					</div>)}
				</div> */}
			</div>
		</div>
	)
}

export default PlaceholderResults

const logosArray = [
	{
		fields: {
			image: "/assets/icons/Kave.svg",
			imageMobile: "/assets/icons/Kave-mob.svg"
		}
	},
	{
		image: "/assets/icons/Bolia.svg",
		imageMobile: "/assets/icons/Bolia-mob.svg"
	},
	{
		image: "/assets/icons/Innovation.svg",
		imageMobile: "/assets/icons/innovation-mob.svg"
	},
	{
		image: "/assets/icons/Furninova.svg",
		imageMobile: "/assets/icons/Furninova-mob.svg"
	},
	{
		image: "/assets/icons/Conform.svg",
		imageMobile: "/assets/icons/Conform-mob.svg"
	}
]
