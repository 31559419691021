import {isEmpty} from "../../../utility/Helper"
import {defaultValue, delimiter} from "../Config"
import {FilterSlugValues, VisibleFilters} from "../SelectedFilters/visibleFilters"

export const getParamValuesArray = (params, param) => {
	return isEmpty(params) ? [] : (params[param] as string)?.split(delimiter) || []
}

export const getBikesPriceRange = bikes => bikes?.reduce((acc, {price}) => {
	const _price = parseInt(price, 10)
	if (acc.min === null) {
		return {min: _price, max: _price}
	}

	if (_price) {
		if (price < acc.min) {
			acc.min = Number(_price)
		}

		if (price > acc.max) {
			acc.max = Number(_price)
		}
	}

	return acc
}, {min: null, max: null})

export const getActiveFilters = params => {
	return Object.keys(params).reduce((acc, filterName) => {
		if (VisibleFilters[filterName] && params[filterName] !== defaultValue) {
			const values = params[filterName].split(delimiter)
			const valuesArr = values.map(value => ({name: filterName, id: filterName + value, value: getSlugValueByTranslatedValue(value)}))
			acc.push(...valuesArr)
		}

		return acc
	}, [])
}

export const getSlugValueByTranslatedValue = (value: string) => FilterSlugValues[value] || value
