import React from "react"
import Typography from "../Typography/Typography"

interface Props {
    t?: (text:string) => string
    name:string
    label:string
    required:boolean
}
const FormLabel:React.FC<Props> = ({
	t,
	name,
	label,
	required
}) => {
	return (
		<label htmlFor={name} className={"text-left mb-2"}>
			<Typography variant={"bodySmBoldMdBodyLgBold"} semanticTag="span">{label}</Typography>{required &&
					<span className="text-danger">*</span>}
		</label>
	)
}

export default FormLabel
