import NavItem from "./NavItem"
import {filterUrl} from "../../filter/FilterUrl"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import NewMegaMenu from "./NewMegaMenu"
import React, {useState} from "react"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import urlParams from "../../../utility/UrlParams"

type Props = {
	closeMegaMenu: any
	isBackgroundOn: boolean
	setIsBackgroundOn: any
	isToggleOpen: boolean
	setIsToggleOpen: any
}

const slugsList = [
	{
		title: "All Furniture",
		buttonTitle: "All Furniture"
	},
	{
		title: "Sofas & Sessel",
		slugName: "sofas,sessel",
		buttonTitle: "All Sofas & Sessel",
		link: "sofas,sessel",
		icon: "/assets/icons/sofa.svg"
	},
	{
		title: "Table & Chairs",
		slugName: "table,chairs",
		buttonTitle: "All Tables & Chairs",
		link: "tische,stuhle",
		icon: "/assets/icons/tables.svg"
	},
	{
		title: "Betten",
		slugName: "bed",
		buttonTitle: "All Beds",
		link: "betten",
		icon: "/assets/icons/bed.svg"
	},
	{
		title: "Aufbewahrung",
		slugName: "aufbewahrung",
		link: "aufbewahrung",
		buttonTitle: "Alle Aufbewahrung",
		icon: "/assets/icons/furniture.svg"
	},
	{
		title: "Dekoration",
		slugName: "dekoration",
		link: "dekoration",
		buttonTitle: "Alle Dekoration",
		icon: "/assets/icons/lamp.svg"
	},
	{
		title: "Outdoor",
		slugName: "outdoor",
		link: "balkon-and-garten",
		buttonTitle: "Alle Outdoor",
		icon: "/assets/icons/outdoor.svg"
	}
]
const DesktopBottomHeader: React.FC<Props> = ({
	isBackgroundOn,
	setIsBackgroundOn,
	isToggleOpen,
	setIsToggleOpen
}) => {
	const {t} = useTranslation("common")
	const tCommon = useTranslation().t
	const [activeItem, setActiveItem] = useState(null)

	const handleMouseEnter = itemTitle => {
		setIsBackgroundOn(true)
		setIsToggleOpen(true)
		setActiveItem(itemTitle)
	}

	const handleMouseLeave = () => {
		setIsToggleOpen(false)
		setActiveItem(null)
	}

	return (
		<div
			style={{minWidth: 400, marginRight: "auto", height: 48}}
			onMouseLeave={handleMouseLeave}
		>
			<ul
				className="d-flex list-unstyled align-items-start justify-content-center mb-0"
				style={{height: "100%", minWidth: 400}}>
				{slugsList.map((item, index) => (
					<div
						key={index}
						className="position-relative pointer col-auto p-0  text-bold"
						onMouseEnter={() => handleMouseEnter(item.title)}
						style={{height: "100%"}}
					>
						<NavItem
							className="d-flex align-items-start"
							action={handleMouseEnter.bind(null, item.title)}
							label={t(item.title)}
							href={
								item?.title === "Alle E-Bikes" ? filterUrl({}, null, null, tCommon) :
									item?.title === "Accessories" ? t("zubehor") : filterUrl({[urlParams.category]: item.link}, null, null, tCommon)}
							classGroup="nav-link-black text-secondary position-relative"
							isNoUppercase
							typographyVariant="bodySm"
							isChevronIcon
							isItemOpen={isBackgroundOn}
							itemTitle={item.title}
							activeItem={activeItem}
							hasNoMargin
							icon={item.title === "All Furniture" ? "" : <Image src={item.icon} width={16} height={16} loading={"lazy"}/>}
						/>
						<div
							style={{left: index < 3 ? 0 : index > 4 ? -70 : -100, minHeight: "100%"}}
							className="overflow-hidden navbar-absolute"
						>
							<AccordionAnimation
								inProp={isToggleOpen && item.title === activeItem}
								duration={400}
								transTimingFn="ease-out"
								onExited={() => setIsBackgroundOn(false)}
								unmount
								customHeight={"700px"}
							>
								<NewMegaMenu toggleMenu={handleMouseLeave} item={item}/>
							</AccordionAnimation>
						</div>
					</div>
				))}
			</ul>
		</div>
	)
}

export default DesktopBottomHeader
