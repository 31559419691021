import React, {useContext, useState} from "react"
import PropTypes from "prop-types"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import FilterContext from "../../../context/FilterContext"
import {FilterItemProps} from "./props/FilterProps"
import {getParamValuesArray} from "../utility/Utility"
import {defaultValue, delimiter} from "../Config"
import {filterUrl} from "../FilterUrl"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import Checkbox from "../../reusable/components/Checkbox/Checkbox"
import slugify from "slugify"
import AnalyticsContext from "../../../context/AnalyticsContext"

const FilterDropdownItem: React.FC<FilterItemProps> = ({
	label,
	param,
	value,
	isBoxShape = false,
	collectionName,
	customUrlBaseValue,
	labelOnly = false,
	className,
	styles
}) => {
	const router = useRouter()
	const params = router.query
	const {t} = useTranslation(["common", "filters"])
	const [loading, setLoading] = useState(false)
	const {filtersLoading, setFiltersLoading} = useContext(FilterContext)
	const slugValue = slugify(t(value, {ns: "filters"})).toLowerCase()
	const {categoryOrBrandName} = useContext(FilterContext)
	const {reactGA} = useContext(AnalyticsContext)

	const handleChange = () => {
		setFiltersLoading(true)
		setLoading(true)

		const paramValues = params[param] === defaultValue ? [] : getParamValuesArray(params, param)
		if (params[param] === defaultValue) {
			paramValues.push(slugValue)
		} else if (paramValues.includes(slugValue)) {
			paramValues.splice(paramValues.indexOf(slugValue), 1)
		} else {
			paramValues.push(slugValue)
		}

		// Check if url includes children categories without parent category and remove them
		/* if (paramValues && paramValues.length) {

			console.log(1, paramValues)
			parentCategories.filter(({slug}) => {

				console.log(2, paramValues)
				return	!paramValues.includes(slug)
			}).forEach(({childrenCategories}) => childrenCategories.forEach(cat => {

				console.log(3, paramValues)
				if (paramValues.includes(slugify(t(cat)).toLocaleLowerCase())) {

					console.log(4, paramValues)
					paramValues.splice(paramValues.indexOf(slugify(t(cat)).toLocaleLowerCase()), 1)
					console.log(5, paramValues)
				}
			}))
		} */

		if (paramValues.length === 0) {
			paramValues.push(defaultValue)
		}

		// Log event
		reactGA?.event({
			category: gaCategories.productFilters,
			action: gaEvents.filteredProducts,
			label: `${param}: ${label}`,
			nonInteraction: false
		})

		const url = filterUrl({...params, page: "-", searchValue: "-"}, param, paramValues.join(delimiter), t, collectionName, categoryOrBrandName, customUrlBaseValue)
		setTimeout(
			() => {
				router.push(url, undefined, {shallow: true, scroll: false}).then(() => {
					setLoading(false)
					setFiltersLoading(false)
				})
			}
			, 0)
	}

	const filterActive = getParamValuesArray(params, param).includes(slugValue)
	return (
		<>
			<div style={{marginBottom: 8}} className={`mb-md-0 d-flex mb-0 ${className ? className : ""}`}>
				{isBoxShape ?
					<div className="cursor-pointer d-flex align-items-center justify-content-center me-2 filter-item-box"
						style={{
							height: 40,
							padding: "10px 16px",
							borderRadius: 16,
							backgroundColor: filterActive ? "#212529" : "",
							border: filterActive ? "1px solid #212529" : "1px solid #DEE2E6",
							color: filterActive ? "#fff" : "#212529"
						}}>
						<Checkbox isBoxShape={true} id={param} isActive={filterActive} label={t(label, {ns: "filters"})}
							isLoading={loading}
							onChange={handleChange} disabled={filtersLoading}/>
					</div> :
					<div style={{marginBottom: 8, ...styles}} className={className ? className : ""}>
						<Checkbox id={param} isActive={filterActive} labelOnly={labelOnly} label={t(label, {ns: "filters"})} isLoading={loading}
							onChange={handleChange} disabled={filtersLoading}/>
					</div>
				}
			</div>
		</>
	)
}

FilterDropdownItem.propTypes = {
	label: PropTypes.string.isRequired,
	param: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired
}

export default FilterDropdownItem
