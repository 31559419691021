import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {FilePdf} from "react-bootstrap-icons"

type Props = {
    file: any
}

const FileUploadThumb: React.FC<Props> = ({file}) => {
	const [loading, setLoading] = useState(false)
	const [thumb, setThumb] = useState(undefined)
	useEffect(() => {
		if (file) {
			setLoading(true)
			const reader = new FileReader()
			reader.onloadend = () => {
				setLoading(false)
				setThumb(reader.result)
			}

			reader.readAsDataURL(file)
		}
	}, [file])

	if (!file) {
		return null
	}

	if (loading) {
		return <p>loading</p>
	}

	return (
		<div className="col-12">
			{loading ? <p>loading</p> : <></>}
			{/* eslint-disable-next-line no-negated-condition */}
			{thumb ? (!file.name.includes(".pdf") ? <img src={thumb} alt={file.name} width={"100%"} height={"auto"}/> : <FilePdf size={40} style={{color: "green"}}/>) : <></>}
		</div>
	)
}

FileUploadThumb.propTypes = {
	file: PropTypes.any
}

export default FileUploadThumb
