import React, {useContext, useEffect} from "react"
import Link from "next/link"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import {getProductUrl} from "../../url/Url"
import SearchContext from "../../context/SearchContext"
import {useRouter} from "next/router"
import PlaceholderResults from "./PlaceholderResults"
import {useTranslation} from "next-i18next"
import RecentSearchesItem from "./RecentSearchesItem"
import {filterUrl} from "../filter/FilterUrl"
import Button from "../reusable/components/Button"
import {colors} from "../../colors/Colors"
import urlParams from "../../utility/UrlParams"

const SearchResults = () => {
	const {isOn, results, value, isLoading, toggle, recentSearchTerms, handleSearch, setRecentSearchTermsRefs, setTermValue} = useContext(SearchContext)
	const {t} = useTranslation(["common", "search"])

	const router = useRouter()

	useEffect(() => {
		router.events.on("routeChangeComplete", () => toggle(false))
		return () => {
			router.events.off("routeChangeComplete", () => toggle(false))
		}
	}, [router.events])

	return (
		isOn ?
			<div style={{
				maxHeight: "80vh",
				overflow: "auto"
			}}>
				{
					recentSearchTerms?.length > 0 && (
						<div className="row mb-xl-2">
							<div className="col-12">
								<div className="ps-3 pt-3 pe-3 pb-0">
									<div className="col-12 mt-2 mb-3">
										<Typography className="text-secondary" variant={"bodyLg"} semanticTag={"div"} >
											{t("Zuletzt angeschaut", {ns: "common"})}
										</Typography>
									</div>

									<div className="col-12 pb-lg-2 d-flex flex-wrap">
										{recentSearchTerms.map(term => (
											<RecentSearchesItem key={term} setRecentSearchTermsRefs={setRecentSearchTermsRefs} handleSearch={handleSearch} term={term} setTermValue={setTermValue}/>
										))}
									</div>

									<div className="d-md-none">
										<hr/>
									</div>

								</div>
							</div>
						</div>
					)
				}

				{isLoading ? <div className={"p-4"}>
					<div className={"row justify-content-center justify-content-lg-start"}>
						<div className={"col-auto"}>
							<span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"/>
						</div>
					</div>
				</div> : results ?
					<div className={"row justify-content-between"}>
						<div className={"col-12 col-lg-6"}>
							<div className={"p-4"}>
								<Typography variant={"bodyLg"} semanticTag={"div"} className={"mb-4 text-secondary"}>
									{t("e-bikes-title", {ns: "search"})}
								</Typography>
								{ results.bikes?.map((bike, index) => {
									return <Link key={index} href={`/produkt/${bike.slug}`} as={getProductUrl(t, bike.slug)}>
										<a className={"d-block mb-3 link-reset"}>
											<div className={"row align-items-center"}>
												<div className={"col-3 position-relative"}>
													<Image src={bike.images[0]?.src} objectFit="contain" layout="responsive" width={1} height={1}/>
												</div>
												<div className={"col"}>
													<Typography variant={"bodySm"} semanticTag={"div"}>
														{bike.brand}
													</Typography>
													<Typography variant={"bodyLgBold"} semanticTag={"div"}>
														{bike.name}
													</Typography>
													<Typography variant={"bodyLg"} semanticTag={"div"}>
														{bike.colors}
													</Typography>
												</div>
											</div>
										</a>
									</Link>
								})}
								{(results.totalNumberOfProducts - results.bikes.length) ? <Button bgColor="transparent" borderColor={colors.primary} outline fontSizeSmall label={t("explore-more-search-results").replace("+", (results.totalNumberOfProducts - results.bikes.length).toString())} color={colors.primary} textColor="black" onClick={() => router.push(filterUrl({[urlParams.searchValue]: value, page: "-"}, null, null, t))} /> : null}

							</div>
						</div>
					</div> : value?.length >= 3 ?
						<Typography variant={"bodyLg"} semanticTag={"div"} className={"p-4 text-secondary"}>
							No results.
						</Typography> : null
				}
				<div className={"p-4"}>
					<PlaceholderResults/>
				</div>
			</div> : null
	)
}

export default SearchResults
