import React from "react"
import Typography from "./Typography/Typography"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import Button from "./components/Button"
import {ArrowClockwise} from "react-bootstrap-icons"

interface Props {
    retryHandler: (value: boolean) => void
}

const FailMessage:React.FC<Props> = ({retryHandler}) => {
	const {t} = useTranslation()
	return (
		<div className="row my-5">
			<div className="col-12 text-center">
				<Image src={"/assets/icons/envelope-fail.svg"} width={48} height={48}/>
				<Typography variant="heading1" semanticTag="h3" className="text-red-danger mt-1">
					{t("Message wasn’t sent")}
				</Typography>
			</div>
			<div className="col-12">
				<Typography variant="bodyLg" semanticTag="p" className="mt-4">
					{t("An unexpected error happened. Please try again.")}
				</Typography>
				<Button outline
					color={"black"}
					className={"mt-4 text-uppercase w-100"}
					icon={<ArrowClockwise />}
					onClick={() => retryHandler(true)}
					label={t("Retry")}
				/>
			</div>
		</div>
	)
}

export default FailMessage
