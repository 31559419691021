import Link from "next/link"
import Typography from "../../reusable/Typography/Typography"
import NavDropdown from "./NavDropdown"
import React from "react"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"

type Props = {
	dropdownData?: {
		label: string;
		items: { itemLabel: string; href: string }[];
	}[];
};

const DesktopTopHeader: React.FC<Props> = ({dropdownData}) => {
	const {t} = useTranslation("common")
	const router = useRouter()

	return (
		<div className="top-navbar d-flex justify-content-between align-items-center py-2" style={{height: "2.4rem"}}>
			<div className="d-flex align-items-center justify-content-end" style={{maxWidth: 462, width: "100%"}}>
				<ul className="d-flex list-unstyled align-items-center mb-0">

					<ul className="d-flex list-unstyled align-items-center position-relative">
						{dropdownData.map(({label, items}) => (
							<NavDropdown
								key={label}
								label={t(label)}
								items={items}
								classGroup={" text-secondary"}
								isNoUppercase
								typographyVariant="bodySm"
								isChevronIcon
							/>
						))}
					</ul>

					<li
						className="col-auto p-0 me-3">
						<Link href={"/kundendienst"} as={t("/kundendienst")}>
							<a className={`pb-1 nav-link-black ${router.asPath === t("/kundendienst") ? "nav-link-active" : ""}`}>
								<Typography variant="bodySm" semanticTag="span" style={{color: "#6C7074"}}>{t("Kundendienst")}</Typography>
							</a>
						</Link>
					</li>
					<li
						className="col-auto p-0">
						<Link href={"/konzept"} as={t("/konzept")}>
							<a className={`pb-1 nav-link-black ${router.asPath === t("/konzept") ? "nav-link-active" : ""}`}>
								<Typography variant="bodySm" semanticTag="span" style={{color: "#6C7074"}}>{t("concept")}</Typography>
							</a>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default DesktopTopHeader
