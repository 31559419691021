import React, {Dispatch, SetStateAction, useContext} from "react"
import Link from "next/link"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import styles from "./navigation.module.scss"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"
import {topCategoriesMegaMenuMHP} from "../../constants/TopCategories"
import Image from "next/image"

type Props = {
	setIsMenuVisible?: Dispatch<SetStateAction<boolean>>
	navItemClassName?: string
	sectionWidth?: number
	megaMenuTopCategories?: any[]
	getOtherInstanceUrl?:(arg0?:string, arg1?:string, arg2?:string) => string
	handleActiveItemSelect?: (itemTitle: string) => void
	handleToggleOpen?: (isOpen: boolean) => void
	activeItem?: string
}

const TopCategoriesMegaMenu = ({activeItem, handleActiveItemSelect = () => {}, handleToggleOpen = () => {}, navItemClassName, sectionWidth, getOtherInstanceUrl, megaMenuTopCategories = topCategoriesMegaMenuMHP}: Props) => {
	const {t} = useTranslation("common")
	const {dark} = colors

	const handleMouseEnter = itemTitle => {
		handleActiveItemSelect(itemTitle)
		handleToggleOpen(true)
	}

	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div style={{maxWidth: sectionWidth ? sectionWidth : 183, width: "100%"}}>
			<div className={`${styles.megaMenuTopCategories} scrollbar-hide`}>
				<ul className="list-unstyled mb-0">
					{megaMenuTopCategories.map(({title, link, translateLink}, idx) => {
						return (<React.Fragment key={idx} >
							{getOtherInstanceUrl ?
							// This portion is for showcasing the Ecosystem tabs megaMenu
								<li
									key={idx} className={`rounded-3 d-flex justify-content-between align-items-center ${styles.megamenuTopCategoryItem} ${navItemClassName}`}
									onMouseEnter={() => handleMouseEnter(title)}
									style={{backgroundColor: activeItem === title ? "rgba(110, 144, 96, 0.20)" : ""}}
								>
									<Link href={ getOtherInstanceUrl("-", link, "-")}>
										<a onClick={() => {
											reactGA?.event({
												category: gaCategories.megaMenu,
												action: gaEvents.category,
												label: `${gaEvents.category} ${title}`,
												nonInteraction: false
											})
										}} className="link-reset d-inline-block">
											<Typography style={{color: dark}} variant={ "bodySm" }>{t(title)}</Typography>
										</a>
									</Link>
								</li> :
								// This portion is for showcasing the NavBars Mega Menu tabs
								<Link href={filterUrl({[urlParams.category]: link}, null, null, t)}><li
									key={idx} className={`rounded-3 d-flex justify-content-between align-items-center ${styles.megamenuTopCategoryItem} ${navItemClassName}`}
									onMouseEnter={() => handleMouseEnter(title)}
									style={{backgroundColor: activeItem === title ? "rgba(110, 144, 96, 0.20)" : ""}}
								>
									<a onClick={() => {
										reactGA?.event({
											category: gaCategories.megaMenu,
											action: gaEvents.category,
											label: `${gaEvents.category} ${title}`,
											nonInteraction: false
										})
									}} className="link-reset col-8 d-inline-block">
										<Typography style={{color: dark}} variant="bodyLg" className="d-none d-md-block">{t(title)}</Typography>
										<Typography style={{color: dark}} variant="bodySm" className="d-md-none">{t(title)}</Typography>
									</a>
									<div className="d-flex col-2">
										<Image src={"/assets/icons/chevron-right.svg"} width={20} height={20}/>
									</div>
								</li></Link>}
						</React.Fragment>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

export default TopCategoriesMegaMenu

const colors = {dark: "#212529", white: "#fff"}
