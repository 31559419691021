import React, {createRef, useEffect} from "react"
import Typography from "../reusable/Typography/Typography"
import PropTypes from "prop-types"

type Props = {
	term: string,
	handleSearch: (value:string)=>void,
	setRecentSearchTermsRefs: Function,
	setTermValue: (value:string)=>void
}

const RecentSearchesItem: React.FC<Props> = ({term, handleSearch, setRecentSearchTermsRefs, setTermValue}) => {
	const ref = createRef()

	useEffect(() => {
		if (ref.current) {
			setRecentSearchTermsRefs(prevState => [
				...prevState,
				ref.current
			])
		}

		return () => {
			setRecentSearchTermsRefs(prevState => prevState.splice(prevState.indexOf(ref), 1))
		}
	}, [])

	return (
		<button ref={ref as any} key={term} className={"d-block bg-grey mb-3 me-3 ps-2 pe-2 rounded border-0"}
			onClick={() => {
				handleSearch(term)
				setTermValue(term)
			}}
		>
			<Typography variant={"bodyLg"} semanticTag={"div"} >
				{term}
			</Typography>
		</button>
	)
}

RecentSearchesItem.propTypes = {
	term: PropTypes.string,
	handleSearch: PropTypes.func,
	setRecentSearchTermsRefs: PropTypes.func,
	setTermValue: PropTypes.func
}

export default RecentSearchesItem
