import React, {useContext} from "react"
import SearchContext from "../../context/SearchContext"
import dynamic from "next/dynamic"

const DynamicSearchField = dynamic(() => import("./SearchField"))

const SearchDesktop:React.FC<any> = () => {
	const {handleSearch, setLoading} = useContext(SearchContext)

	return <DynamicSearchField
		onStartTyping={() => setLoading(true)}
		onEndTyping={value => {
			handleSearch(value)
		}} onType={undefined} className={"form-control border bg-white search-field w-100"}	/>
}

export default SearchDesktop
