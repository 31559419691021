import React from "react"
import Typography from "../Typography/Typography"

interface Props {
    t?: (text:string) => string
    label:string
}
const FormLabelUploadSmall:React.FC<Props> = ({
	t,
	label
}) => {
	return (
		<>
			<Typography variant={"bodySm"} className="d-block d-md-none text-grey text-center" semanticTag="div">{label}</Typography>
			<Typography variant={"bodySm"} className="d-none d-md-block text-grey text-left" semanticTag="div">{label}</Typography>
		</>
	)
}

export default FormLabelUploadSmall
