import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import DataContext from "../../context/DataContext"
import Button from "../reusable/components/Button"
import {ModalContext} from "../../context/ModalContext"
import BookAppointmentModalContent from "../reusable/ModalsContent/BookAppointmentModalContent"
import ContentfulCustomModalContent from "../reusable/ModalsContent/ContentfulCustomModalContent"
import DiscountForNewsletterModalContent from "../reusable/ModalsContent/DiscountForNewsletterModalContent"
import Image from "next/image"
import {content} from "../../constants/Instances"

enum Action {
	newsletter = "Newsletter",
	bookConsulation = "Book Consulation",
	link = "Link",
	customModal = "Custom Modal"
}

type Props = {
	isRounded?: boolean
	style?: any
	hardcodedLink?:string
	hasDiscountForNewsletterModal?: boolean
}

const StickyBar: React.FC<Props> = ({isRounded, style, hardcodedLink, hasDiscountForNewsletterModal}) => {
	const {t} = useTranslation("common")
	const {pageProps: {stickyBar}} = useContext(DataContext)
	const {setModalContent, closeModal, openModal} = useContext(ModalContext)
	return (
		<>
			{stickyBar && stickyBar.length ? <div className={`mt-md-0 bg-primary-pale d-flex justify-content-center align-items-end ${isRounded && "rounded"}`} style={{padding: "6px 12px", ...style}}>
				<div className={"row justify-content-center mb-1"}>
					{process.env.NEXT_PUBLIC_ORGANIZATION ?
						<div className="d-flex flex-wrap justify-content-center">
							<div className="d-flex align-items-center text-start">
								<div className="d-none d-md-flex align-items-center">
									<Image src="/assets/icons/fire.svg" width="24px" height="24px" alt="fire" loading="lazy"
									/>
								</div>
								<div className="d-md-none d-flex align-items-center">
									<Image src="/assets/icons/fire.svg" width="20px" height="20px" alt="fire" loading="lazy"
									/>
								</div>
								<Typography variant={"bodySm"} semanticTag={"span"} className="ms-2 ms-md-3 me-md-1">{content[process.env.NEXT_PUBLIC_ORGANIZATION]?.stickyBar.text}</Typography>
							</div>
						</div> :
						stickyBar.map(({fields}, index) => {
							const {action, link, buttonText} = fields
							const BlackFridayDiscount = "50 % Rabatt auf 3 Monate"
							return (
								<div className="d-flex flex-wrap justify-content-center" key={index}>
									<div className="d-flex align-items-center text-start">
										<div className="d-none d-md-flex align-items-center">
											<Image src="/assets/icons/fire.svg" width="24px" height="24px" alt="fire" loading="lazy"
											/>
										</div>
										<div className="d-md-none d-flex align-items-center">
											<Image src="/assets/icons/fire.svg" width="20px" height="20px" alt="fire" loading="lazy"
											/>
										</div>
										<Typography variant={"bodySm"} semanticTag={"span"} className="ms-2 ms-md-3 me-md-1">{BlackFridayDiscount}</Typography>
									</div>
									{hardcodedLink && buttonText && <Button
										className="ms-2"
										outline
										borderColor={"#805B34"}
										paddingY="0"
										paddingX="0"
										isNoUppercase
										href={ hardcodedLink }
									>
										<Typography className="px-1" variant={"bodySmBold"}>{buttonText}</Typography>
									</Button>}
									{!hardcodedLink && hasDiscountForNewsletterModal && buttonText && <Button
										className=""
										outline
										borderColor={"#805B34"}
										paddingY="0"
										paddingX="0"
										isNoUppercase
										onClick={() => {
											setModalContent(<DiscountForNewsletterModalContent closeModal={closeModal}/>)
											openModal()
										}}
									>
										<Typography className="px-1" variant={"bodySmBold"}>{buttonText}</Typography>
									</Button>}

									{!hardcodedLink && action === Action.bookConsulation && !hasDiscountForNewsletterModal && buttonText && <Button
										className="ms-2"
										outline
										borderColor={"#805B34"}
										paddingY="0"
										paddingX="0"
										isNoUppercase
										onClick={() => {
											setModalContent(<BookAppointmentModalContent closeModal={closeModal}/>)
											openModal()
										}}
									>
										<Typography className="px-1" variant={"bodySmBold"}>{buttonText}</Typography>
									</Button>}

									{!hardcodedLink && action === Action.customModal && !hasDiscountForNewsletterModal && buttonText && <Button
										className="ms-2"
										outline
										borderColor={"#805B34"}
										paddingY="0"
										paddingX="0"
										isNoUppercase
										onClick={() => {
											setModalContent(<ContentfulCustomModalContent/>)
											openModal()
										}}
									>
										<Typography className="px-1" variant={"bodySmBold"}>{buttonText}</Typography>
									</Button>}

									{!hardcodedLink && action === Action.link && !hasDiscountForNewsletterModal && buttonText && <Button
										className="ms-2"
										outline
										borderColor={"#805B34"}
										paddingY="0"
										paddingX="0"
										isNoUppercase
										href={hardcodedLink ? hardcodedLink : link}
									>
										<Typography className="px-1" variant={"bodySmBold"}>{buttonText}</Typography>
									</Button>}

								</div>
							)
						})
					}
				</div>
			</div> : null}
		</>
	)
}

export default StickyBar
