import React, {useContext} from "react"
import CartContext from "../../context/CartContext"
import LayoutContext from "../../context/LayoutContext"

const CartIndicator: React.FC = () => {
	const {items} = useContext(CartContext)
	const {isMobile} = useContext(LayoutContext)
	return (
		<>
			{items && items.length ? <span className={"d-block d-flex align-items-center justify-content-center position-absolute rounded-circle bg-primary"}
				style={{
					width: 16,
					height: 16,
					padding: "0.5px 4px",
					lineHeight: "16px",
					top: isMobile ? 0 : -10,
					right: isMobile ? 0 : -10,
					fontSize: "12px",
					fontWeight: 500,
					color: "#fff"
				}}>
				{items.length}
			</span> : null}
		</>

	)
}

export default CartIndicator
