import React, {useContext} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import CartIndicator from "../reusable/CartIndicator"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
    isFrontpage?: boolean
    mobile?: boolean
}

const CartButton: React.FC<Props> = ({isFrontpage, mobile}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	return (
		<Link href={"/checkout"} as={t("/checkout")}>
			<a className={mobile ? "btn position-relative d-flex align-items-center px-2" : "position-relative"} onClick={() => {
				reactGA?.event({
					category: gaCategories.cart,
					action: gaEvents.seeCart,
					label: gaEvents.seeCart,
					nonInteraction: false
				})
			}}>
				<img src={"/assets/icons/cart-icon.svg"} />
				<CartIndicator/>
			</a>
		</Link>
	)
}

CartButton.propTypes = {
	isFrontpage: PropTypes.bool,
	mobile: PropTypes.bool
}

export default CartButton
