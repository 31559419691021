export const getReviewsData = () => {
	const reviewsObj = reviewsData
	reviewsObj.reviews = reviewsObj.reviews.filter(review => review.comment.length >= 80)
	return reviewsObj
}

const reviewsData = {
	reviews: [
		{
			reviewId: "AbFvOqk1ujRzWeu0zhvVo-6hIVK3tKBQcOx7HUI_PTvndXOqzGx2Ju52-3V5R-E-SceChTW4POSoSg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJykjEa7g3yQgTWR_duoLXubrir-K7PrBNBlytPS=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Roger Staerkle"
			},
			starRating: "ONE",
			comment: "sehr schlechte übergabe vom lieferant und keine instruktion von meinem gekauften kalkhoff velo. mängel musste ich selber beim velomech organisieren, bin stark unzufrieden",
			createTime: "2021-10-01T16:42:12.334Z",
			updateTime: "2021-10-01T16:42:12.334Z",
			reviewReply: {
				comment: "Guten Tag Herr Staerkle\n\nDanke für Ihre Bewertung. Wir möchten uns für Ihre nicht zufriedenstellende Erfahrung entschuldigen.  Wir versuchen unser Bestes, um Ihnen die bestmögliche Erfahrung zu bieten, da Ihre Zufriedenheit uns wichtig ist.\n\nWas den Service betrifft, so wurde Ihnen der Vorschlag gemacht, die lokale Werkstatt zubesuchen, da der Prozess schneller abläuft, da es bei einer Abholung und Überprüfung bei uns etwas länger dauern könnte, bis Sie das E-Bike wieder zurück haben. Wie gesagt holen wir Ihr E-Bike auch gerne ab und lassen es in unserer internen Werkstatt überprüfen.\n\nBeste Grüsse\nDas MyBikePlan Team",
				updateTime: "2021-10-04T09:08:52.234Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqk1ujRzWeu0zhvVo-6hIVK3tKBQcOx7HUI_PTvndXOqzGx2Ju52-3V5R-E-SceChTW4POSoSg",
			lang: "de"
		},
		{
			reviewId: "AbFvOqmK-2uJOfi0Q-XzQrwT9LAwHizF9aFarUTBR5qAeUpa6YMAb9bv8pivQdb1FiveshUBoTHGvw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyjSBnwRfmJ2P56sfDngAH90Tz4KIZTPAxva4nG=s120-c-c0x00000000-cc-rp-mo-ba4-br100",
				displayName: "Marion Merz"
			},
			starRating: "FIVE",
			comment: "Sehr freundlicher Kundendienst, gute Beratung und schnelle Lieferung. Top 👍👏👏🌻",
			createTime: "2021-08-24T08:54:45.624Z",
			updateTime: "2021-08-24T08:54:45.624Z",
			reviewReply: {
				comment: "Besten Dank für die Bewertung, das freut uns sehr! 🤩 🙏",
				updateTime: "2021-08-24T09:26:48.846Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmK-2uJOfi0Q-XzQrwT9LAwHizF9aFarUTBR5qAeUpa6YMAb9bv8pivQdb1FiveshUBoTHGvw",
			lang: "de"
		},
		{
			reviewId: "AbFvOqmZdWhq5pauubbsz3u3ZMZ3dMyU9nS8dXQaRBaPb3tUcBm5PS-L2vlYspli3hzEo201K0TyUw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJzbs2tdCNhY-jEB7DTnECp7gErTJeK7cvCsJ0tF=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Jürgen Stock"
			},
			starRating: "FOUR",
			comment: "Schnelle Antworten. Alles super geklappt. Sehr schnelle Lieferung. Bin vollkommen begeistert.",
			createTime: "2021-08-07T07:57:39.597Z",
			updateTime: "2021-08-07T07:57:39.597Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung! Beste Grüsse und wir wünschen viel Spass mit dem E-Bike! :-)",
				updateTime: "2021-08-16T08:50:06.392Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmZdWhq5pauubbsz3u3ZMZ3dMyU9nS8dXQaRBaPb3tUcBm5PS-L2vlYspli3hzEo201K0TyUw",
			lang: "de"
		},
		{
			reviewId: "AbFvOqnnwgPNpvhuAsZufrcqGT3hmi-bSAxfoLUlXICV-GxFolJfV9iNXgBf8cUHpicgjfN7Jgv3JQ",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJzGO-3fFvnYTc0pFjTwlvxcAuxmU1DUHdeOZtOF=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "thomas baur"
			},
			starRating: "FIVE",
			comment: "Hat alles super geklappt bin sehr zufrieden kann My Bike Plan jedem empfehlen",
			createTime: "2021-08-04T08:45:41.408Z",
			updateTime: "2021-08-04T08:45:41.408Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung & viel Spass mit dem E-Bike! :-)",
				updateTime: "2021-08-05T07:06:45.105Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnnwgPNpvhuAsZufrcqGT3hmi-bSAxfoLUlXICV-GxFolJfV9iNXgBf8cUHpicgjfN7Jgv3JQ",
			lang: "de"
		},
		{
			reviewId: "AbFvOqlEAgcN1eN0P47_QFjX4FmryVyaR-QywAdwiVuiEr3OUJAR9YdD3YLGHT7ioUr-qZdDU9empA",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyIeReiJt1d1zk31Uo_VvhpGWw44apsEcVgty-E=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Denise Thüring"
			},
			starRating: "FIVE",
			comment: "Mein E-Bike ist absolut genial zum fahren. Ich bin total zufrieden.",
			createTime: "2021-07-30T13:09:57.529Z",
			updateTime: "2021-07-30T13:09:57.529Z",
			reviewReply: {
				comment: "Das freut uns, vielen Dank für die Bewertung! :-)",
				updateTime: "2021-08-03T11:22:19.647Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlEAgcN1eN0P47_QFjX4FmryVyaR-QywAdwiVuiEr3OUJAR9YdD3YLGHT7ioUr-qZdDU9empA",
			lang: "de"
		},
		{
			reviewId: "AbFvOqm-YRbJl5-0IEbRjgiKw4v2w-TPpkA9Z2ZM7a5DIsyCZa7edz_MlARPSuqJiq4Zr7Xg0dLr7w",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyk_ytP6-0q3XIEHw_b-LLMMCHVwFRiHo8uAafF=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Daniela Beyeler"
			},
			starRating: "FIVE",
			comment: "Ich würde nie mehr ein normales Bike fahren, bin mehr als zufrieden mit meinem E-Bike 🚲",
			createTime: "2021-07-28T10:31:12.371Z",
			updateTime: "2021-07-28T10:31:12.371Z",
			reviewReply: {
				comment: "Vielen Dank - Das finden wir toll! 😎",
				updateTime: "2021-07-29T06:57:14.232Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqm-YRbJl5-0IEbRjgiKw4v2w-TPpkA9Z2ZM7a5DIsyCZa7edz_MlARPSuqJiq4Zr7Xg0dLr7w",
			lang: "de"
		},
		{
			reviewId: "AbFvOqlnt-NZTUEAtSRNkm_x1rAVPtBlogRxka1yCeR0vdiD0jo71fJE6XD7TLyOaJO-1dbkv3YVUA",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GiLcT8lF5lOAVl1peG3fVSiPQsuQlERLu8M_R_MRg=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Tini Zu"
			},
			starRating: "FIVE",
			comment: "Ausgesucht  bestellt und schon ist es da! Ja, ich dachte immer \"schau mer mal\" so habe ich mein Bike bestellt und kurz darauf wurde ich auch schon angerufen, es wurde mir per Mail ein Video link geschickt und somit hatte ich reichlich Informationen über mein Bike! Aber auch die Spedition Firma war der Hammer! Super Kommunikation, netter und  Hilfsbereiter Kontakt. Ich bin wirklich überrascht wie in so kurzer Zeit alles über die Bühne ging!! Bravo 👏",
			createTime: "2021-07-28T00:17:53.705Z",
			updateTime: "2021-07-28T00:17:53.705Z",
			reviewReply: {
				comment: "Vielen lieben Dank für die Bewertung! Wir freuen uns, das zu lesen und wünschen viel Spass! 😀",
				updateTime: "2021-07-29T06:56:27.694Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlnt-NZTUEAtSRNkm_x1rAVPtBlogRxka1yCeR0vdiD0jo71fJE6XD7TLyOaJO-1dbkv3YVUA",
			lang: "de"
		},
		{
			reviewId: "AbFvOqkTeII9CgHeaCnndbqycTO_Zp_uHz6VCQqJTj4UpIk8TtEeOZKE_HLKDAIamMT5sxxgqV539g",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJx4LMHn1pf-LXpq-QJJWUEeFX91eVjUYnDHkMhG=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Marco Zemp"
			},
			starRating: "FIVE",
			comment: "Super Service vom A bis Z. Nur zu empfehlen",
			createTime: "2021-07-26T09:53:34.833Z",
			updateTime: "2021-07-26T09:53:34.833Z",
			reviewReply: {
				comment: "Vielen Dank! :-)",
				updateTime: "2021-07-26T11:27:35.068Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkTeII9CgHeaCnndbqycTO_Zp_uHz6VCQqJTj4UpIk8TtEeOZKE_HLKDAIamMT5sxxgqV539g",
			lang: "de"
		},
		{
			reviewId: "AbFvOqmpEg8Peo8wZvS5x2ATtNTMo3rIJ1kQmPaT7Xr6BwSeZxwQFXlTByzWTKUDv4fDNqIJAbOo",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJwG-Gzn6t8eKTbMWlDGp6pmaKD0CyPM1zlADpOq=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Jacqueline Kubli"
			},
			starRating: "FIVE",
			comment: "Voll und ganz zufrieden. Super E-Bike. Wir haben 3 E-Bike von MyBikePlan und alle sind top. Wir 3 sind alle begeistert. Und das Logistik-Center inkl. Auslieferdienst sind sehr freundlich.",
			createTime: "2021-07-23T11:43:59.144Z",
			updateTime: "2021-07-23T11:43:59.144Z",
			reviewReply: {
				comment: "Vielen Dank für das positive Feedback! Wir freuen uns sehr darüber und wünschen viel Spass! :-)",
				updateTime: "2021-07-26T07:10:32.062Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmpEg8Peo8wZvS5x2ATtNTMo3rIJ1kQmPaT7Xr6BwSeZxwQFXlTByzWTKUDv4fDNqIJAbOo",
			lang: "de"
		},
		{
			reviewId: "AbFvOqlsTbsbEA9LLXZIS1bwOkYvecxbGRM46Hak4VO0sqT88VNAS7nESu4lE183OyjkK8Ee_zSdVQ",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GizSpel56J5vOmaoEZWPTOIq1BtVenkMrhwrCfG_Q=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Paul Schwab"
			},
			starRating: "FIVE",
			comment: "Bin sehr zufrieden... Es hat alles tip top geklappt",
			createTime: "2021-07-23T10:41:06.750Z",
			updateTime: "2021-07-23T10:41:06.750Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung & weiterhin viel Freude!",
				updateTime: "2021-07-26T07:09:51.212Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlsTbsbEA9LLXZIS1bwOkYvecxbGRM46Hak4VO0sqT88VNAS7nESu4lE183OyjkK8Ee_zSdVQ",
			lang: "de"
		},
		{
			reviewId: "AbFvOqnYi4FwZK-kzjtD7nMdGg_2TzfbHhCo5JkfTETcxczQca4UhZ3aDz2crBbPYu1yAhoo0e20AQ",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GgaXe-rP461_6y2mb7dE96eN67FBwRVq8cVfFTyUNg=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Peter Meier"
			},
			starRating: "FIVE",
			comment: "Habe Heute mein focus jam2 6.7 nine magic black erhalten. Wow...wie cool ist denn dieses Bike! Ist genau so, wie es bei MyBikePlan angeboten wurde. Ich wurde immer auf dem laufenden gehalten, was die Lieferzeit betrifft, denn momentan ist ja alles ein wenig komplizierter und umständlicher wegen der sehr grossen Nachfrage auf dem Bike-Markt. Da kann ich dem ganzen MyBikePlan-Team nur ein Kränzchen winden und mich von ganzem Herzen bedanken. Ihr gebt wirklich euer Bestes und betreut eure Kunden kompetent und zuverlässig. Alle Fragen und Anliegen werden prompt beantwortet.\nAuch die Lieferung verlief reibungslos und der Chauffeur war sehr nett und hilfsbereit.\nVielen, vielen Dank liebes MyBikePlan-Team.\nIhr seit Spitze! ❤",
			createTime: "2021-07-22T18:40:39.660Z",
			updateTime: "2021-07-22T18:40:39.660Z",
			reviewReply: {
				comment: "Vielen lieben Dank für die Bewertung - wir freuen uns riesig darüber! :-) Wir wünschen Ihnen von Herzen viel Spass mit Ihrem E-Bike! 🚴😎",
				updateTime: "2021-07-23T09:08:22.945Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnYi4FwZK-kzjtD7nMdGg_2TzfbHhCo5JkfTETcxczQca4UhZ3aDz2crBbPYu1yAhoo0e20AQ",
			lang: "de"
		},
		{
			reviewId: "AbFvOqnvpE0QlQ5_Ri-__HfJwItI193t17ICb4lKBm5UQd0zCt88v1nSA_EwCUdDfOLDJ147E_fdUg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GjaoXntNo2E7ikjrDYt2EhMVY_rbeCD0ZUNA5Gykmk=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Domenic Bochtler"
			},
			starRating: "FIVE",
			comment: "Sehr einfache und unkomplizierte abwicklung. Man wurde über alles Informiert und auf dem laufenden gehalten. Alles wirklich sehr sauber und sensationell geklappt. Das Fahrrad wurde sogar 2 Wochen vor dem Liefertermin geliefert. 🤗",
			createTime: "2021-07-17T07:23:41.799Z",
			updateTime: "2021-07-17T07:23:41.799Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung! Es freut uns sehr, dies zu lesen und wir wünschen Ihnen viel Spass mit dem E-Bike! :-)",
				updateTime: "2021-07-22T07:12:07.150Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnvpE0QlQ5_Ri-__HfJwItI193t17ICb4lKBm5UQd0zCt88v1nSA_EwCUdDfOLDJ147E_fdUg",
			lang: "de"
		},
		{
			reviewId: "AbFvOqm7L98WqDAOs5_IbzGmTFQSl0zjcQCDssfRaeItue88zLtNV46RxE5bxzmX-0Tz-DwXo2e2",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJwZE6gagTWjuktar463wwCfaI_AydGaDe4Pov8Z=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "David Pacheco"
			},
			starRating: "FIVE",
			comment: "Je recommande vivement, avec moi tout se c'est très bien passé, j'ai reçu mon e-bike même avant le délai et je suis absolument un client satisfait!",
			createTime: "2021-07-16T10:17:07.062Z",
			updateTime: "2021-07-16T10:17:07.062Z",
			reviewReply: {
				comment: "Merci pour ce beau retour ! Nous te souhaitons plein de belles aventures sur la route ! :-)",
				updateTime: "2021-07-23T06:25:05.456Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqm7L98WqDAOs5_IbzGmTFQSl0zjcQCDssfRaeItue88zLtNV46RxE5bxzmX-0Tz-DwXo2e2",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqmVHBV-B9RP9-4Dka7D-V1L303-ld7Bor9jfws8SKrW1uHloo8yK4xUaaoK07Dmm9GnWVa3WA",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyOrLI2DvGq4dkgJUQmT0jBTY_-1Jm5Su0pkPD3=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
				displayName: "Andri Spiller"
			},
			starRating: "THREE",
			comment: "War zunächst etwas skeptisch, hat aber alles super geklappt und heute düse ich durch die Gegend.\n\nUPDATE: So schnell wie man an sein eBike kommt, so dürftig ist der Service sobald man es hat. Deshalb nur noch drei Sterne.",
			createTime: "2021-07-14T09:22:05.174Z",
			updateTime: "2021-07-14T09:22:05.174Z",
			reviewReply: {
				comment: "Super, das freut uns! Viel Spass weiterhin! :-)",
				updateTime: "2021-04-29T07:14:12.271Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmVHBV-B9RP9-4Dka7D-V1L303-ld7Bor9jfws8SKrW1uHloo8yK4xUaaoK07Dmm9GnWVa3WA",
			lang: "de"
		},
		{
			reviewId: "AbFvOqn3LPOfini5v8133QMR2a6SQO6Nl0KRANHrOMGxCpdbWNsRgNXoIkNcAELJ2WHRcYO-ykwRxA",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GgUl-9EkojdXWT5f4naadjfRiNX8yXnT285Vg87tQ=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Mike Lowsky"
			},
			starRating: "FIVE",
			comment: "Sehr schnelle und reibungslose Abwicklungen.\nKundendienst bietet gute Auskünfte und ist hilfsbereit.",
			createTime: "2021-07-13T10:00:45.868Z",
			updateTime: "2021-07-13T10:00:45.868Z",
			reviewReply: {
				comment: "Vielen lieben Dank für das Feedback, wir schätzen das sehr :-)",
				updateTime: "2021-07-15T14:38:21.115Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqn3LPOfini5v8133QMR2a6SQO6Nl0KRANHrOMGxCpdbWNsRgNXoIkNcAELJ2WHRcYO-ykwRxA",
			lang: "de"
		},
		{
			reviewId: "AbFvOqkNiD7mcJ4ZLGrThSWmzrctsf4qNiNCm8pbZI5C0hMUc8NDJyP1x77LhlYUVfWAgpPF19R8FA",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GjBJTfRPHuJsuEJtWjHdRV_UD9m4jGVZ2Pp92tzUw=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Maik Hollandmoritz"
			},
			starRating: "FIVE",
			comment: "Sehr professionelle Abwicklung sehr netter Kontakt und schnelle Lieferung! Kann ich wirklich sehr empfehlen!!!",
			createTime: "2021-07-02T12:38:15.881Z",
			updateTime: "2021-07-02T12:38:15.881Z",
			reviewReply: {
				comment: "Guten Tag - Vielen Dank für das Feedback, das freut uns sehr! :-) Wir wünschen viel Spass!",
				updateTime: "2021-07-09T09:17:06.411Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkNiD7mcJ4ZLGrThSWmzrctsf4qNiNCm8pbZI5C0hMUc8NDJyP1x77LhlYUVfWAgpPF19R8FA",
			lang: "de"
		},
		{
			reviewId: "AbFvOqmRMzaxYd1KMHr6WTknZ-xO7GX82XmltorgLgZimdTSw-zA0cpR0s6jLzU_xAMHa7sOrkTU",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJznjm1eNynY_KUgI3FnWH_ja-1YyOOLfpgCS-iv=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Prune Texier"
			},
			starRating: "FIVE",
			comment: "Topissime !",
			createTime: "2021-07-01T10:01:44.051Z",
			updateTime: "2021-07-01T10:01:44.051Z",
			reviewReply: {
				comment: "Merci beaucoup pour ce joli retour ! :-)",
				updateTime: "2021-07-09T14:01:02.486Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmRMzaxYd1KMHr6WTknZ-xO7GX82XmltorgLgZimdTSw-zA0cpR0s6jLzU_xAMHa7sOrkTU",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqkpaKVG9rtpHZbJkpqMSWCR-fp2GjN1llJ-QHJLqLWlYZ396mwVcLicPSFKFpMeANNKVlw9dw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GiTzlJosAOqinQA1xgzhCHha8du8HQVGwBtMKbD=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Tiago Almeida"
			},
			starRating: "FIVE",
			comment: "Genial 🤩 kann ich nur weiter empfehlen! Hätte nie gedacht das es so einfach und unkompliziert ist. Sehr freundliche und hilfsbereit.",
			createTime: "2021-06-27T18:43:26.970Z",
			updateTime: "2021-06-27T18:43:26.970Z",
			reviewReply: {
				comment: "Vielen lieben Dank für die Bewertung! Wir wünschen viel Spass :-)",
				updateTime: "2021-06-28T07:42:15.120Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkpaKVG9rtpHZbJkpqMSWCR-fp2GjN1llJ-QHJLqLWlYZ396mwVcLicPSFKFpMeANNKVlw9dw",
			lang: "de"
		},
		{
			reviewId: "AbFvOqkOT5jDPfhhN19pjBUTN0gOd6HlJtAuAEyXedMNc-zND3otVTctivwK2-K2SIrDmFHizwoUbw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GhddkCWx2ZBmMdwmYloIzRGKrEKMMXBO3EJI2da=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "R. Berger"
			},
			starRating: "FIVE",
			comment: "Hat soweit alles i.O. geklappt. Focus Jam 6.7 ist ein sehr schönes Bike und war vollstens zufrieden mit der Lieferung. War alles mit dabei, funktioniert alles einwandfrei und bin nun glücklicher E-Bike Fahrer! Vielen Dank!",
			createTime: "2021-06-27T11:43:21.653Z",
			updateTime: "2021-06-27T11:43:21.653Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung! Wir freuen uns, dies zu lesen und wünschen viel Spass mit dem E-Bike! :-)",
				updateTime: "2021-06-28T07:42:54.384Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkOT5jDPfhhN19pjBUTN0gOd6HlJtAuAEyXedMNc-zND3otVTctivwK2-K2SIrDmFHizwoUbw",
			lang: "de"
		},
		{
			reviewId: "AbFvOqkggM-YNWLIDACRZ2tC4y8iuVe6_QQN0GH-cdT3aZ15rTVFF7XhjdjM3CwyYk9FxJbz62amAg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GhUgOXqOBVy-t_tF_kp3GPmRUMZgNYsNJIBEcs9RA=s120-c-c0x00000000-cc-rp-mo-ba3-br100",
				displayName: "Pires Carlos"
			},
			starRating: "FIVE",
			comment: "Excellente entreprise. Je suis vraiment content du service et prestations.",
			createTime: "2021-06-25T20:06:41.735Z",
			updateTime: "2021-06-25T20:06:41.735Z",
			reviewReply: {
				comment: "Nous sommes ravis de vous savoir satisfait ! Nous vous souhaitons de bons moments avec votre nouvel E-Bike! :-)",
				updateTime: "2021-06-28T07:37:16.108Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkggM-YNWLIDACRZ2tC4y8iuVe6_QQN0GH-cdT3aZ15rTVFF7XhjdjM3CwyYk9FxJbz62amAg",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqnklpyn6y4ihIalx_W9Oe6o545r-fjl69ubuWyiQ8Q-TsJYWKxhmGJr5LP4SwweIsa1YCJC",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14Gi5vZhEYS-sE_QMC9RwoNgRwyOSRCCBkhy-HGdB=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Lanquetin Yann"
			},
			starRating: "FIVE",
			comment: "Top 👍🏼",
			createTime: "2021-06-25T19:20:23.037Z",
			updateTime: "2021-06-25T19:20:23.037Z",
			reviewReply: {
				comment: "Merci pour votre message Yann. Nous sommes ravis de savoir que vous avez appréciez notre service et espèrons que vous passerez d'excellents moments sur la route avec votre E-Bike. :-)",
				updateTime: "2021-06-28T07:41:52.002Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnklpyn6y4ihIalx_W9Oe6o545r-fjl69ubuWyiQ8Q-TsJYWKxhmGJr5LP4SwweIsa1YCJC",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqllo1lZW5DsgS--EbahLO16qi4Rr6HUoclnOs30dQU0JeouBj8nnP5u5C94jMWlv80oHNn8iw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14Ghf_pmA1SQUqgERvKXjmzsHKNVSPjhGdSU721MK1A=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "SaJaBa unterwegs"
			},
			starRating: "THREE",
			comment: "Schnell und freundlich. Es hat alles gut geklappt. Was ich negativ fand, das Bike sollte angeblich fertig montiert und fahrfertig geliefert werden. Nun ist es einen Tag da und beim Velomech. Bremsen müssen neu eingestellt werden, Schaltung ebenso, um die Batterie länger am Leben zu halten, wäre Polfett toll gewesen. Ebenso muss die Federung auf das Gewicht richtig angepasst werden. Es wäre toll gewesen, wenn diese Dinge vor der Auslieferung richtig gemacht werden.",
			createTime: "2021-06-25T09:43:21.391Z",
			updateTime: "2021-06-25T09:43:21.391Z",
			reviewReply: {
				comment: "Besten Dank für die Rückmeldung. Es tut uns Leid, dass Sie diese Erfahrung gemacht haben. Könnten Sie mit uns Kontakt aufnehmen? Wir würden dies gerne mit Ihnen besprechen :-).\n\nBeste Grüsse und bis bald, das MyBikePlan Team",
				updateTime: "2021-06-25T13:05:23.679Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqllo1lZW5DsgS--EbahLO16qi4Rr6HUoclnOs30dQU0JeouBj8nnP5u5C94jMWlv80oHNn8iw",
			lang: "de"
		},
		{
			reviewId: "AbFvOqkduosjivazyb-VJTGU5YpizQFMiP4ev1lpGfFB0gnxEmR7ymb68fZIVgYq5JV1rlBk2dI5TQ",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJzfj_JOpp89QCePOBBvhsd9awI0ByREaGTtsTgN=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Victor Da Silva"
			},
			starRating: "FOUR",
			comment: "Traitement très simple et réponse rapide. Processus efficace sur la commande et un délai de livraison assez court. La livraison s'effectue au pas de la porte, ce qui est appréciable.\n\nLe suivi client est efficace, je leur souhaite le meilleur pour la suite et plein de réussite.",
			createTime: "2021-06-24T11:45:36.833Z",
			updateTime: "2021-06-24T11:45:36.833Z",
			reviewReply: {
				comment: "Merci encore pour votre retour constructif Victor ! Nous vous souhaitons de très bons moments avec votre nouvel E-Bike! :-)",
				updateTime: "2021-06-28T07:36:58.351Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkduosjivazyb-VJTGU5YpizQFMiP4ev1lpGfFB0gnxEmR7ymb68fZIVgYq5JV1rlBk2dI5TQ",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqlDNY4rcvvXxVk93zDetAQQO4h5tfQ9OJTK1ZYfysyq0taxlvnkQmMKpdWUqo-v599k4MNAAg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GiuP25ZJb-zM5BT1AZZNGtuo_V-FSiDPgcRqXC_=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Nathalie Haab"
			},
			starRating: "FIVE",
			comment: "Un service clientèle très réactif et rapide; une commande exécutée très rapidement, la livraison promise sous 2 à 3 semaines est arrivée au bout de 7 jours, le vélo était prêt à rouler. Service 5 étoiles, je recommande!",
			createTime: "2021-06-23T06:08:40.463Z",
			updateTime: "2021-06-23T06:08:40.463Z",
			reviewReply: {
				comment: "Merci pour ce super commentaire Nathalie ! Nous vous souhaitons une belle continuation avec votre E-Bike! :-)",
				updateTime: "2021-06-28T07:36:37.479Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlDNY4rcvvXxVk93zDetAQQO4h5tfQ9OJTK1ZYfysyq0taxlvnkQmMKpdWUqo-v599k4MNAAg",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqmXfz7YvWgMcDGySWvkrFqsakBfsNBaD4YsjxbMUgiwfZMByjuvVdWAl4dp7Tv7EyxYk6jY",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14Ghvt9_CRFsLUnmrokQ2hV_AMCHhmhLfCoQAvmWKLA=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Sabrina Schaub"
			},
			starRating: "FIVE",
			comment: "Top Service! Sehr freundliche Kontaktpersonen & super Abwicklung! Der Spediteur hat das Bine sogar auf dem Balkon im 2. OG deponiert! Einfach top!",
			createTime: "2021-06-22T21:11:39.255Z",
			updateTime: "2021-06-22T21:11:39.255Z",
			reviewReply: {
				comment: "Vielen Dank für das Feedback! Wir schätzen das sehr :-)",
				updateTime: "2021-06-24T07:36:38.466Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqmXfz7YvWgMcDGySWvkrFqsakBfsNBaD4YsjxbMUgiwfZMByjuvVdWAl4dp7Tv7EyxYk6jY",
			lang: "de"
		},
		{
			reviewId: "AbFvOqkAj-R_r5VfbD2jOgLXQgtUYjfyuEjpgvz9eH1XkRsBlaEdIi_fqwn8L6ggHVLQ2_SNptHx",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14Ghpp2b4n5b8RzFne_qEkSFJfh3KMITMSql2W1mu=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "ツJoel ツ"
			},
			starRating: "FIVE",
			comment: "Super",
			createTime: "2021-06-22T20:54:30.227Z",
			updateTime: "2021-06-22T20:54:30.227Z",
			reviewReply: {
				comment: "Vielen Dank für das tolle Feedback! :-)",
				updateTime: "2021-06-28T07:43:23.458Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkAj-R_r5VfbD2jOgLXQgtUYjfyuEjpgvz9eH1XkRsBlaEdIi_fqwn8L6ggHVLQ2_SNptHx",
			lang: "de"
		},
		{
			reviewId: "AbFvOqnF6PE1dyymxJu6xqTimK3nxEXPhYWidfUPi06OOAziX3DH4t-ixdWR47FIFUWHBNpy3O3sDw",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJxKyMW96FPh0BkQhoYaq7XmDTHgqFxf0D6obEY0=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "julien Tarallo"
			},
			starRating: "FIVE",
			comment: "Voilà j ai bien reçu mon vélo un poil en avance super contact je recommande vivement",
			createTime: "2021-06-22T13:35:53.943Z",
			updateTime: "2021-06-22T13:35:53.943Z",
			reviewReply: {
				comment: "Merci pour votre retour Julien ! Nous vous souhaitons plein de bons moments avec votre nouvel E-Bike! :-)",
				updateTime: "2021-06-28T07:36:07.184Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqnF6PE1dyymxJu6xqTimK3nxEXPhYWidfUPi06OOAziX3DH4t-ixdWR47FIFUWHBNpy3O3sDw",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqn2nAWkrWnaxGNyo4H_oPnI_IxwmhkDZY70vFWFOAcdpxXAzgfb6yNjTfoRrs5bTAlztoS8",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJy7uKD-B5enSqu9IAPeuyKmJmayRglglZCm0SLl=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Stefan Strach"
			},
			starRating: "FIVE",
			comment: "Vollkommen zufrieden das Bike ist der Hammer",
			createTime: "2021-06-22T09:49:08.963Z",
			updateTime: "2021-06-22T09:49:08.963Z",
			reviewReply: {
				comment: "Vielen Dank für die Bewertung, Stefan! Viel Spass! :-)",
				updateTime: "2021-06-24T07:34:57.183Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqn2nAWkrWnaxGNyo4H_oPnI_IxwmhkDZY70vFWFOAcdpxXAzgfb6yNjTfoRrs5bTAlztoS8",
			lang: "de"
		},
		{
			reviewId: "AbFvOqkqbDjm8fl2QJgxKCX92iBCrCcd3d9dBMlpCAnh-KLgIxOF1gL5QF8oOUiuCqxtiWN_mcAkqg",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJzMFqNHvqMVZLmPgmj5BGe7fUfEBQ8iJWYPGLeU=s120-c-c0x00000000-cc-rp-mo-ba2-br100",
				displayName: "P. Kehl"
			},
			starRating: "FIVE",
			comment: "Vielen lieben Dank euch!!! Alles wunderbar geklappt 👍👍👍",
			createTime: "2021-06-21T16:31:32.804Z",
			updateTime: "2021-06-21T16:31:32.804Z",
			reviewReply: {
				comment: "Vielen Dank für die positive Rückmeldung, das freut uns sehr! :-)",
				updateTime: "2021-06-22T07:28:50.882Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqkqbDjm8fl2QJgxKCX92iBCrCcd3d9dBMlpCAnh-KLgIxOF1gL5QF8oOUiuCqxtiWN_mcAkqg",
			lang: "de"
		},
		{
			reviewId: "AbFvOqlxemutPIo-ySe4sytL3qwSyXvF7GlYXEpixbL-MMujtL1O46h7h_NIv6_zeRPXNTU3BS0p",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a-/AOh14GgdW1sFfCBBUIKv8FXawSK0Itt4S4kxa-KTHNimUA=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "paris oscar"
			},
			starRating: "FIVE",
			comment: "Merci à toute l'équipe. Un peux d'attente due à la forte demande mais un  très bon suivi des délais. Livraison impeccable avec un livreur super arrangeant. Et un vélo sans la moindre marque. Je recommande à 100%.",
			createTime: "2021-06-21T04:38:31.723Z",
			updateTime: "2021-06-21T04:38:31.723Z",
			reviewReply: {
				comment: "Merci pour ton avis ! Nous espèrons que tu passeras des moments 100% agréable avec ton nouvel E-Bike !  😊",
				updateTime: "2021-06-22T07:43:19.278Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlxemutPIo-ySe4sytL3qwSyXvF7GlYXEpixbL-MMujtL1O46h7h_NIv6_zeRPXNTU3BS0p",
			lang: "fr"
		},
		{
			reviewId: "AbFvOqlrH1gP5ECsThrnU_S3vPgfCOsTLEXbsdLfGQo7eHE0kPaZk4IKo_xE8UEEUq9-YuZ8HgI9",
			reviewer: {
				profilePhotoUrl: "https://lh3.googleusercontent.com/a/AATXAJyJesR10vOzraFKhzo2YoyxkJffQX1dnYvi2lLA=s120-c-c0x00000000-cc-rp-mo-br100",
				displayName: "Marion Helg"
			},
			starRating: "FIVE",
			comment: "Unkomplizierte Abwicklung, prompte Lieferung, Super Fahrräder",
			createTime: "2021-06-19T09:43:43.267Z",
			updateTime: "2021-06-19T09:43:43.267Z",
			reviewReply: {
				comment: "Merci vielmals fürs Feedback, das freut uns!",
				updateTime: "2021-06-21T06:12:32.930Z"
			},
			name: "accounts/106959175307212025644/locations/7459562148523747963/reviews/AbFvOqlrH1gP5ECsThrnU_S3vPgfCOsTLEXbsdLfGQo7eHE0kPaZk4IKo_xE8UEEUq9-YuZ8HgI9",
			lang: "de"
		}
	],
	averageRating: 4.6,
	totalReviewCount: 300,
	nextPageToken: "ABHRLXUcOYw2x5VfukeNa8sMAouWbA5WWXb7ugRfDA6lMQd-Kinpm6Y"
}

