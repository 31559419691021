import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import React from "react"
import NewMegaMenu from "../desktop/NewMegaMenu"
import {useTranslation} from "next-i18next"

type Props = {
	setIsEbikesMenuVisible?: any
	isEbikesMenuVisible?: any
	activeCategory?: any
	setIsMenuVisible?: any
}

const EbikesMenu: React.FC<Props> = ({setIsEbikesMenuVisible, setIsMenuVisible, isEbikesMenuVisible, activeCategory}) => {
	const {t} = useTranslation()
	return (
		<>
			<AnimationComponent type="slideRight" inProp={isEbikesMenuVisible}>
				<div
					className={"position-fixed border-top bg-white start-0 px-2 pt-4 overflow-auto"}
					style={{zIndex: 99999, top: 94, width: "100%", height: "100%", paddingBottom: 250}}>
					<div className="d-flex align-items-center py-2 mb-3">
						<Image
							src="/assets/icons/arrow-left.svg"
							width={20}
							height={20}
							alt={"arrow left"}
							className="cursor-pointer"
							onClick={() => setIsEbikesMenuVisible(false)}
						/>
						<Typography variant="heading3MdBodyLgBold" semanticTag="span" className="ms-3">{t(activeCategory?.title)} {activeCategory?.title !== "All furniture" && activeCategory?.title !== "Accessories" && null} </Typography>
					</div>
					<NewMegaMenu item={activeCategory} setIsMobileMenuVisible={setIsMenuVisible}/>
				</div>
			</AnimationComponent>

		</>
	)
}

export default EbikesMenu
