import Image from "next/image"
import Link from "next/link"
import SearchDesktop from "../../search/SearchDesktop"
import WhatsAppButton from "../WhatsAppButton"
import CartButton from "../CartButton"
import React from "react"

type Props = {
	setIsSidebarOpen: any
	isFrontPage: boolean
}

const DesktopMidHeader: React.FC<Props> = ({isFrontPage, setIsSidebarOpen}) => {
	return (
		<div className="d-flex justify-content-between align-items-center" style={{margin: "12px 0px"}}>
			<div className="col-3 d-flex align-items-center" style={{columnGap: 16, minHeight: 40}}>
				<Link href={"/"} as={"/"}>
					<a className="d-inline-flex align-items-center">
						<Image
							className="pointer main-logo"
							width={223}
							objectFit="contain"
							height={28}
							src="/assets/MyHomePlan-logo.svg"
							alt="MyHomePlan logo"
							style={{marginTop: 1}}
						/>
					</a>
				</Link>
			</div>
			<div className="col-5">
				<SearchDesktop/>
			</div>
			<div className={" d-flex align-items-center col-3 justify-content-end"}>
				<div className="me-4">
					<WhatsAppButton/></div>
				<CartButton
					isFrontpage={isFrontPage}
				/>
			</div>
		</div>
	)
}

export default DesktopMidHeader
