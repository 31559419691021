import React from "react"
import {useField, useFormikContext} from "formik"
import FileUploadThumb from "../reusable/FileUploadThumb"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import Typography from "./Typography/Typography"
import Image from "next/image"

const UploadField: React.FC<any> = ({isNewDesign = false, icon = "", ...props}) => {
	const {t} = useTranslation()
	const {setFieldValue, errors, touched} = useFormikContext()
	const [field] = useField(props)

	return (
		<>
			<div className="mb-3 d-block d-md-none">
				<div className={"d-grid text-center"}>
					<input {...props} onChange={event => {
						setFieldValue(field.name, event.currentTarget.files[0])
					}} hidden/>
					{/* <label htmlFor={field.name} className="border-grey px-3 py-3 text-20 pointer bg-dark text-white"> */}
					{
						isNewDesign ? <label htmlFor={field.name} className="d-flex justify-content-center text-uppercase border-grey border-2 px-3 py-2 pointer">
							{icon && <Image src={icon} width={20} height={20}/>}
							<Typography className="ms-2" variant="bodyLgBold" semanticTag="span">{t(props.labeltext)}</Typography>
						</label> :
							<label htmlFor={field.name} className="border-grey border-2 px-3 py-3 text-20 pointer fs-3 fw-bold">
								{t(props.labeltext)}
							</label>
					}
					<div className={"mt-2"}>
						<FileUploadThumb file={field.value}/>
					</div>
				</div>
			</div>
			<div className={`row mb-3 d-none d-md-block ${errors[field.name] && touched[field.name] && "p-1 "}`}>
				<div className={"col-12"}>
					<input {...props} onChange={event => {
						setFieldValue(field.name, event.currentTarget.files[0])
					}} hidden/>
					<label htmlFor={field.name} className="border-grey px-3 py-2 text-20 pointer">
						{t(props.labeltext)}
					</label>
					<div className={"mt-2"}>
						<FileUploadThumb file={field.value}/>
					</div>
				</div>
			</div>
		</>

	)
}

UploadField.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.string,
	labeltext: PropTypes.string,
	isNewDesign: PropTypes.bool,
	icon: PropTypes.string
}

export default UploadField
