import React from "react"
import Link from "next/link"
import Typography from "../Typography/Typography"
import {useTranslation} from "next-i18next"
import {colors} from "../../../colors/Colors"
import {ChevronRight} from "react-bootstrap-icons"

type Props = {
  href: string
  label: string
	icon?: React.ReactNode
	iconMargin?: any
  color?: string
	labelVariant?: any
	iconBgColor?: string
	padding?: string
}

const LinkWithIcon = ({href, label, color = "#212529", icon, labelVariant = "bodyLgBold", iconBgColor = "#6E9060", padding, iconMargin}: Props): React.ReactElement => {
	const {t} = useTranslation()
	const {primary, white} = colors
	return (
		<Link href={href}>
			<a className={"text-decoration-none d-inline-block"} style={{color: "#212529"}}>
				<div className="d-flex align-items-center rounded read-more-btn" style={{padding: padding ? padding : "8px"}}>
					<Typography variant={labelVariant} semanticTag="span">{t(label)}</Typography>
					{icon ?
						<div className={`d-flex align-items-center ${iconMargin ? iconMargin : ""}`}>{icon}</div> :
						<div className="d-flex justify-content-center align-items-center"
							style={{width: 20, height: 20, background: iconBgColor ? iconBgColor : `${primary}`, borderRadius: "50%", marginLeft: 10}}>
							{/* <Image src={"/assets/icons/arrow-right-mob.svg"} width={6.5} height={12}/> */}
							<ChevronRight color="white" width={15} height={15}/>
						</div>
					}
				</div>
			</a>
		</Link>
	)
}

export default LinkWithIcon
