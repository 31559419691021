import React, {useContext, useMemo} from "react"
import DesktopNavBar from "./desktop/DesktopNavBar"
import MobileNavBar from "./mobile/MobileNavBar"
import PropTypes from "prop-types"
import {getMenuTitles, MenuTitlesArray} from "../../utility/Helper"
import DataContext from "../../context/DataContext"

export type MenuItem = {
    name: string
    href: string,
	src: string,
	imgDimensions: any
}

export type MenuTitle = {
    name: string
    items: MenuItem[]
}
export type MenuTitles = {
    desktop: MenuTitlesArray[]
    mobile: MenuTitlesArray[]
}

type Props = {
	absolute?: boolean
	customLanguageSwitcher?:React.ReactNode
	stickyTop?: boolean
}

const NavBar = ({absolute = false, customLanguageSwitcher = null, stickyTop = true}: Props) => {
	const {collections, brands} = useContext(DataContext)
	const menuTitles = useMemo(() => getMenuTitles(collections, brands), [collections, brands])
	return (
		<>
			<MobileNavBar menuTitles={menuTitles.mobile} absolute={absolute} stickyTop={stickyTop}/>
			<DesktopNavBar menuTitles={menuTitles.desktop} absolute={absolute}/>
		</>
	)
}

NavBar.propTypes = {
	absolute: PropTypes.bool,
	customLanguageSwitcher: PropTypes.node
}

export default NavBar
